import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { HttpLink } from "apollo-link-http";
import fetch from "node-fetch";
import introspectionQueryResultData from "./fragmentTypes.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// "https://db.onsite.fun/graphql",
// "http://localhost:5001/graphql",

const httpLink = new HttpLink({
  uri: "https://europe-west3-virtualevents.cloudfunctions.net/api/graphql",
  fetch: fetch,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("auth-token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Acts as "middleware" for directing our operations over http or via web sockets
const terminatingLink = httpLink;
// Create a new client to make requests with, use the appropriate link returned
// by termintating link (either ws or http)
const client = new ApolloClient({
  ssrForceFetchDelay: 100,
  cache: new InMemoryCache({
    fragmentMatcher,
  }).restore(window.__APOLLO_STATE__),
  link: authLink.concat(terminatingLink),
});

export default client;
