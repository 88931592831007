import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import TagManager from "react-gtm-module";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";

const tagManagerArgs = {
  gtmId: "GTM-K97MQTH",
};

Sentry.init({
  dsn: "https://e35f4d4b865a41a293c471bd007e0a6b@o1155102.ingest.sentry.io/6235331",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

TagManager.initialize(tagManagerArgs);

const render = ReactDOM.hydrate;

render(<App />, document.getElementById("root"));
