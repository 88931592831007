import React, { Fragment, Suspense, lazy } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import * as serviceWorker from "./serviceWorker";
import Pace from "./shared/components/Pace";

import { ApolloProvider } from "@apollo/react-hooks";
import client from "./client";
import CacheBuster from "./CacheBuster";

const Components = lazy(() => import("./logged_in/components/Main"));

function App() {
  React.useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) {
          return null;
        }

        if (!loading && !isLatestVersion) {
          console.log("refreshAndReaload");
          refreshCacheAndReload();
        }
        return (
          <Router>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <GlobalStyles />
              {typeof document !== "undefined" ? (
                <Fragment>
                  <Pace color={theme.palette.primary.light} />
                  <Suspense fallback={<Fragment />}>
                    <ApolloProvider client={client}>
                      <Components />
                    </ApolloProvider>
                  </Suspense>
                </Fragment>
              ) : (
                <ApolloProvider client={client}>
                  <Components />
                </ApolloProvider>
              )}
            </MuiThemeProvider>
          </Router>
        );
      }}
    </CacheBuster>
  );
}

serviceWorker.register();

export default App;
