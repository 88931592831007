import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

// colors
// #FEB63F
// #C70057 vermell
const primary = "#E95548"; //EA6247
const secondary = "#FAEEEE";
const black = "#343a40";
const darkBlack = "#0D698B";
const background = "#ffffff"; //eceff1
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
    common: {
      black,
      darkBlack,
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: background,
    },
    spacing,
  },
  shape: {
    borderRadius: "6px",
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      xs,
    },
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth,
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        position: "static",
      },
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing * 3,
        paddingRight: spacing * 3,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
    MuiAvatar: {
      root: {
        backgroundColor: "#FFFFFF",
        color: "#FFFFFF",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth,
      },
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth: borderWidth,
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "12px",
        width: "100%",
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing,
      },
    },

    MuiTooltip: {
      tooltip: {
        backgroundColor: "#343a40",
        color: "#FFFFFF",
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: "12px",
      },
    },
    MuiPaper: {
      root: {
        borderRadius: "12px",
      },
      elevation1: {
        boxShadow:
          "rgba(0, 0, 0, 0.04) 0px 0px 4px 0px, rgba(0, 0, 0, 0.08) 0px 8px 16px 0px",
        padding: "0.5rem 0px",
      },
      elevation4: {
        boxShadow:
          "rgba(0, 0, 0, 0.04) 0px 0px 4px 0px, rgba(0, 0, 0, 0.08) 0px 8px 16px 0px",
        padding: "0.5rem 0px",
      },
      elevation8: {
        boxShadow:
          "rgba(0, 0, 0, 0.04) 0px 0px 4px 0px, rgba(0, 0, 0, 0.08) 0px 8px 16px 0px",
        padding: "0.5rem 0px",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "6px",
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: "6px",
      },
    },
    MuiFormControl: {
      root: {
        borderRadius: "6px",
      },
    },
    MuiSlider: {
      root: {
        color: primary,
        height: 8,
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
          boxShadow: "inherit",
        },
      },
      active: {},
      valueLabel: {
        left: "calc(-50% + 4px)",
      },
      track: {
        height: 8,
        borderRadius: 4,
      },
      rail: {
        height: 8,
        borderRadius: 4,
      },
    },
    MuiSkeleton: {
      text: {
        borderRadius: "4px",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "6px",
      },

      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&:focus": {
          boxShadow: "none",
        },
      },
    },
  },

  typography: {
    useNextVariants: true,
    fontFamily: "Inter",
    button: {
      textTransform: "none",
    },
  },
});

export default responsiveFontSizes(theme);
